import moment from "moment";
import { convert24To12 } from ".";
import { sendRegistrationConfirmationMail } from "../http-calls";

const EmailFormatter = {
  sendEventRegistrationEmail: async ({
    event,
    userEmail,
    selectedTimeslot,
  }) => {
    const emailContent = EmailFormatter._prepareEmailBody({
      event,
      selectedTimeslot,
    });
    sendRegistrationConfirmationMail({
      EmailContent: emailContent,
      ToEmail: userEmail,
      FromEmail: "iarenewal@concordebattery.com",
      EmailSubject: "IA Renewal 2025 Registration Confirmation",
    });
  },
  _prepareEmailBody: ({ event, selectedTimeslot }) => {
    if (event?.focus === "South Carolina") {
      return `
        <!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <title>
    </title>
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
        #outlook a {
            padding: 0;
        }

        body {
            margin: 0;
            padding: 0;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }

        table,
        td {
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }

        img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }

        p {
            display: block;
            margin: 13px 0;
        }
    </style>
    <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
    <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
    <style type="text/css">
        @media only screen and (min-width:480px) {
            .mj-column-per-100 {
                width: 100% !important;
                max-width: 100%;
            }
        }
    </style>
    <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 {
            width: 100% !important;
            max-width: 100%;
        }
    </style>
    <style type="text/css">
        @media only screen and (max-width:480px) {
            table.mj-full-width-mobile {
                width: 100% !important;
            }

            td.mj-full-width-mobile {
                width: auto !important;
            }
        }
    </style>
</head>

<body style="word-spacing:normal;">
    <div style="">
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div style="margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix"
                                style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                    style="vertical-align:top;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td align="center"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                    style="border-collapse:collapse;border-spacing:0px;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="width:500px;">
                                                                <img height="auto"
                                                                    src="https://res.cloudinary.com/dqzurhnfk/image/upload/v1665420066/external-projects/IA_SouthCarolinaLogo_V1_yjvsfg.jpg"
                                                                    style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;"
                                                                    width="500" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <p
                                                    style="border-top:solid 4px #680A27;font-size:1px;margin:0px auto;width:100%;">
                                                </p>
                                                <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 4px #680A27;font-size:1px;margin:0px auto;width:550px;" role="presentation" width="550px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    Congratulations! You are registered for Concorde’s 2025 South
                                                    Carolina IA Renewal in West Columbia, SC on February 3, 2025.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    <br /> Please review the following event information and guidelines
                                                    established with and approved by the FAA -</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    1. The IA Refresher is eight hours in length. You must be present
                                                    for 8 sessions to meet the minimum eight-hour requirements for IA
                                                    Renewal.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    2. The opening session will begin promptly at 8 AM EDT. Please allow
                                                    extra time for check-in, visiting with friends and settling in.
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    3. Hours 2-7 will have concurrent sessions. You may choose the
                                                    session you would like to attend on the day of the event.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    4. Please ensure that this email address matches the email address
                                                    you use to access FAASafety.gov. If the email addresses do not
                                                    match, you will not automatically receive IA and WINGS credit for
                                                    your attendance.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    5. Following the completion of the event, and FAA approval, your
                                                    certificate of completion will be available to you through the User
                                                    Portal at <a
                                                        href="https://portal.concordebattery.com/login">https://portal.concordebattery.com/login</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:16px;line-height:24px;text-align:left;color:black;">
                                                    <br />
                                                    <br />
                                                    <br /> If you have questions, please contact <a
                                                        href="mailto:iarenewal@concordebattery.com">iarenewal@concordebattery.com.</a>
                                                    <br />
                                                    <br /> We look forward to your participation in this event!
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:14px;line-height:24px;text-align:center;color:black;">
                                                    <br />
                                                    <br />
                                                    <br /> Concorde Battery Corporation | 2009 San Bernardino Road |
                                                    West Covina, CA 91790 U.S.A.
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center"
                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div
                                                    style="font-family:helvetica;font-size:14px;line-height:24px;text-align:center;color:black;">
                                                    <a href="www.concordebattery.com">www.concordebattery.com</a> | ISO
                                                    9001/AS9100</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]></td></tr></table><![endif]-->
    </div>
</body>

</html>
        `;
    } else {
        return `
        <!DOCTYPE html>
<html>
    <head>
        <meta charset="UTF-8">
        <title>Concorde Virtual IA Renewal Series</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
            /* Mobile Styles */
            @media only screen and (max-width: 600px) {
                body {
                    font-size: 16px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
                table {
                    width: 100%;
                }
                td {
                    display: block;
                    width: 100%;
                }
                ol {
                    margin-left: 1.5em;
                }
            }
        </style>
    </head>
    <body style="font-family: Arial, sans-serif; font-size: 18px; line-height: 1.5;">
        <table cellpadding="0" cellspacing="0" border="0" width="100%" style="max-width: 600px; margin: 0 auto;">
            <tr>
                <td align="center">
                    <img src="https://res.cloudinary.com/dqzurhnfk/image/upload/v1698041036/2024_IA_Virtual_Logo_uihkpc.jpg" alt="" style="display: block; margin: 0 auto;">
                </td>
            </tr>
            <tr>
                <td align="center">
                    <h1 style="font-size: 24px; margin: 20px 0;">Congratulations!</h1>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="margin: 20px 0;">You are registered for the 2025 Concorde Virtual IA Renewal Series.</p>
                    <p style="margin: 20px 0;">You have selected to attend the ${event.eventName} aviation seminar from ${convert24To12(
                        selectedTimeslot.startTime
                      )} ET to  ${convert24To12(
                        selectedTimeslot.endTime
                      )} ET on January ${moment(event.startDate, "DD/MM/YYYY").format(
                        "DD"
                      )} and ${moment(event.endDate, "DD/MM/YYYY").format(
                        "DD"
                      )}, 2025.</p>
                    <p style="margin: 20px 0;">Please review the following event information and guidelines established with and approved by the FAA -</p>
                    <ol style="margin: 20px 0;">
                        <li>In the weeks prior to the event, you will receive an email with meeting details for the event. This information will also be available on the Concorde User Portal. Do not share the meeting credentials. This link is unique to you. Each attendee must be registered individually in order to be eligible for IA credit. Attendees who have not registered for the event will not receive credit and may be removed from the meeting.<br>This email will also include instructions on checking system requirements to avoid any connectivity issues on the day of the seminar. Please follow these instructions to avoid any complications.</li>
                        <li>Each seminar day is four hours in length. You must be present for both meetings to meet the minimum eight-hour requirements for IA Renewal. Please note the days you have selected.</li>
                        <li>Each day will begin promptly at the scheduled time. Please log in at least five minutes prior to the event to ensure your connection.</li>
                        <li>Each hour will consist of a 50-minute training session and a 10-minute break.</li>
                        <li>Per FAA guidelines, each attendee will be required to meet minimum attendance requirements to substantiate presence and participation in the course. To measure this, a poll question will appear on your screen during each presentation. The timing of the question will vary from hour to hour. It is critical that you respond to each poll question in order to receive IA credit for your attendance.</li>
                        <li>Please ensure the email address that you used to register matches the email address you use to access FAAsafety.gov. If the email addresses do not match, you will not receive IA and WINGS credit in your FAASaftey account automatically.</li>
                        <li>Following the completion of the event and confirmation of your participation, your Certificate of Completion will be available to you through the User Portal at <a href="https://portal.concordebattery.com/login" style="color: #0072c6; text-decoration: none;">https://portal.concordebattery.com/login</a>.</li>
                    </ol>
                    <p style="margin: 20px 0;">If there are any questions, please contact <a href="mailto:iarenewal@concordebattery.com" style="color: #0072c6; text-decoration: none;">iarenewal@concordebattery.com</a>.</p>
                    <p style="margin: 20px 0;">We look forward to your participation in this event!</p>
                    <p style="margin: 20px 0;">Concorde Battery Corporation | 2009 San Bernardino Road | West Covina, CA 91790 U.S.A.</p>
                    <p style="margin: 20px 0;"><a href="https://www.concordebattery.com" style="color: #0072c6; text-decoration: none;">www.concordebattery.com</a> | ISO 9001/AS9100</p>
                </td>
            </tr>
        </table>
    </body>
</html>
`;
    }
  },
};

export default EmailFormatter;
