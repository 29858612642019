import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { convert24To12 } from "../../helper-methods";
import {
  eventRegistration,
  sendRegistrationConfirmationMail,
} from "../../http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import moment from "moment";
import "./event-viewer.scss";
import { EventEmitter } from "../../utils/event-emitter";
import ReactTooltip from "react-tooltip";
import { cancelEventRegistration } from "../../http-calls/index";
import EmailFormatter from "../../helper-methods/email-formatter";
import EventSponsorsViewer from "../event-sponsors-viewer/event-sponsors-viewer";
import FeaturedSponsorViewer from "../featured-sponsor-viewer/featured-sponsor-viewer";
import SpeakersVideoGrid from "../speakers-video-grid/speakers-video-grid";
import ProfileHelper from "../../admin-module/helpers/profile-helper";

const EventViewer = (props) => {
  const {
    event,
    onSuccess,
    showRegisteredActions = false,
    onlyVideos = false,
  } = props;

  const [isAopMember, setIsAopMember] = useState(false);
  const [aopNumber, setAopNumber] = useState("");

  const showProfileUpdateAlert = (profile) => {
    Swal.fire({
      title: "<strong>AOPA Membership</strong>",
      html: `
      <div style="display: flex; flex-direction: column; align-items: start; margin-bottom: 23px; gap: 5px; margin-left: px">
      <label style="display: flex; align-items: center; font-size: 21px; margin-bottom: 5px;">
        <input type="radio" name="aopaStatus" id="aopaMember" value="YES" style="margin-right: 5px; accent-color: #007bff;">
        AOPA Member
      </label>
      <label style="display: flex; align-items: center; font-size: 21px;">
        <input type="radio" name="aopaStatus" id="notAopaMember" value="NO" style="margin-right: 5px; accent-color: #007bff;">
        Not AOPA Member
      </label>
    </div>
    <input type="text" id="aopNumberInput" class="swal2-input" placeholder="Enter your AOPA number" style="display: none; font-size: 21px; padding: 8px; margin-top: 5px; border-radius: 4px; border: 1px solid #ccc;">
      `,
      showCancelButton: true,
      confirmButtonText: "Update",
      customClass: {
        confirmButton: "swal2-confirm btn btn-primary",
        cancelButton: "swal2-cancel btn btn-secondary",
      },
      // add a custom wrapper class to the popup
      customClass: {
        popup: "custom-popup-class",
        header: "custom-header-class",
        title: "custom-title-class",
      },
      preConfirm: () => {
        const isAopMemberChecked =
          document.getElementById("aopaMember").checked;
        const aopNumberInput = document.getElementById("aopNumberInput").value;

        return {
          isAopMember: isAopMemberChecked,
          aopNumber: isAopMemberChecked ? aopNumberInput : null,
        };
      },
      didOpen: () => {
        const aopaMemberRadio = document.getElementById("aopaMember");
        const notAopaMemberRadio = document.getElementById("notAopaMember");
        const aopNumberInput = document.getElementById("aopNumberInput");

        // Add event listeners to show/hide the AOPA number input based on the selected radio button
        aopaMemberRadio.addEventListener("change", () => {
          aopNumberInput.style.display = "block"; // Show input if "AOPA Member" is selected
        });
        notAopaMemberRadio.addEventListener("change", () => {
          aopNumberInput.style.display = "none"; // Hide input if "Not AOPA Member" is selected
        });

        // // Preselect based on the existing profile data
        // if (profile.isAopMember === 'YES') {
        //   aopaMemberRadio.checked = true;
        //   aopNumberInput.style.display = 'block';
        //   aopNumberInput.value = profile.aopNumber || '';
        // } else {
        //   notAopaMemberRadio.checked = true;
        //   aopNumberInput.style.display = 'none';
        // }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { isAopMember, aopNumber } = result.value;
        console.log("AOPA Member:", isAopMember);
        console.log("AOPA Number:", aopNumber);

        // Call ProfileHelper to update the profile
        await ProfileHelper.updateProfile({
          profile: props.userData,
          isAopMember: isAopMember ? "YES" : "NO",
          aopNumber: isAopMember ? aopNumber : "",
        });

        Swal.fire(
          "Profile Updated",
          "Your AOPA membership details have been updated successfully",
          "success"
        );
      }
    });
  };

  const _showRegisterConfirmationPrompt = (selectedSlot) => {
    Swal.fire({
      title: "Please confirm the time slot",
      text: `Selected Timeslot: ${convert24To12(
        selectedSlot.startTime
      )} - ${convert24To12(selectedSlot.endTime)} Eastern`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      console.log("event :>> ", event);
      if (result.isConfirmed) {
        props.showLoader();
        const payload = {
          eventId: event.eventId,
          eventSlotId: selectedSlot.slotId,
          focusId: event.focusId,
        };
        try {
          _sendConfirmationMails(selectedSlot);
          _notifyAdmins(selectedSlot);
          await eventRegistration(payload);
          onSuccess();
          let showAopForm = false;
          if (event.focus === "Piston") {
            showAopForm = ProfileHelper.checkIfAOPisFilled(props.userData);
          }
          if (showAopForm) {
            showProfileUpdateAlert();
          } else {
            Swal.fire(
              "Registered!",
              "Your slot has been booked successfully",
              "success"
            );
          }
        } catch (error) {
          console.log("error :>> ", error);
          props.hideLoader();
          Swal.fire("Registered Failed", "Please try again later", "error");
        }
      }
    });
  };

  const _sendConfirmationMails = (selectedTimeslot) => {
    const {
      userData: { email: userEmail },
    } = props;
    // console.log("event", event);
    // console.log("userEmail", userEmail);
    // console.log('event', event);
    console.log({
      event,
      userEmail,
      selectedTimeslot,
    });
    EmailFormatter.sendEventRegistrationEmail({
      event,
      userEmail,
      selectedTimeslot,
    });
  };

  const _notifyAdmins = (selectedTimeslot) => {
    const recipents = [
      "nholck@concordebattery.com",
      "cholder@concordebattery.com",
    ];
    const {
      userData: { email: userEmail, first_name, last_name },
    } = props;
    let emailContent = `<html><body>
      <h4>New registration</h4>
      <p>User name: ${first_name} ${last_name}</p>
      <p>User email: ${userEmail}</p>
      <p>Event registered for : ${event.eventName}</p>
      <p>Date and Time: ${convert24To12(
        selectedTimeslot.startTime
      )} EST to ${convert24To12(
      selectedTimeslot.endTime
    )} EST on January ${moment(event.startDate, "DD/MM/YYYY").format(
      "DD"
    )} and ${moment(event.endDate, "DD/MM/YYYY").format("DD")}, 2022</p>
    </body></html>`;
    recipents.forEach((recipent) => {
      console.log({
        EmailContent: emailContent,
        ToEmail: recipent,
        FromEmail: "iarenewal@concordebattery.com",
        EmailSubject: `New registration:  ${event.eventName}`,
      });
      sendRegistrationConfirmationMail({
        EmailContent: emailContent,
        ToEmail: recipent,
        FromEmail: "iarenewal@concordebattery.com",
        EmailSubject: `New registration:  ${event.eventName}`,
      });
    });
  };

  const _showRegisterCancellationPrompt = () => {
    Swal.fire({
      title: "Are you sure to cancel registration?",
      text: `Event: ${event.eventName}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      console.log("event :>> ", event);
      if (result.isConfirmed) {
        props.showLoader();
        const payload = {
          eventId: event.eventId,
        };
        try {
          await cancelEventRegistration(payload);
          onSuccess();
          Swal.fire("Registration Canceled");
        } catch (error) {
          console.log("error :>> ", error);
          props.hideLoader();
          Swal.fire("Action Failed", "Please try again later", "error");
        }
      }
    });
  };

  const _showSpeakerDetails = (speaker) => {
    EventEmitter.dispatch("show-speaker-details", speaker);
  };

  const _getJoinUrl = (event) => {
    if (event?.slots?.length) {
      const registeredSlot = event.slots.find((slot) => slot.alreadyRegistered);
      if (registeredSlot?.joinUrl) {
        return registeredSlot.joinUrl;
      }
    }
  };

  const _getCertLink = (event) => {
    if (event?.slots?.length) {
      const registeredSlot = event.slots.find((slot) => slot.alreadyRegistered);
      if (registeredSlot?.certLink) {
        return registeredSlot.certLink;
      }
    }
  };

  const _downloadCert = () => {
    const certLink = _getCertLink(event);
    const { document } = window;
    const a = document.createElement("a");
    a.href = certLink;
    a.download = certLink.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const _renderEventDetails = () => {
    if (event.focusId === "tt41d8cd98f00b204e9800998ecf842ww") {
      // "South Carolina"
      return (
        <>
          <ul className="list-star castars">
            <li>Join Us In Person at Midlands Technical College</li>
            <li>2 Courses Offered Each Hour</li>
            <li>Catered Lunch Provided</li>
            <li>Door Prizes Throughout the Day</li>
            <li>Free to Attend</li>
            <li>8 Hours of IA Credit Upon Completion</li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <ul className="list-star">
            <li>FAA Approved IA Refresher Training Seminar</li>
            <li>Free Event - Open to All</li>
            <li>Two Convenient Times - Select your Preference</li>
            <li>Up to 8 Hours of IA Credit Upon Completion</li>
            <li>Speakers are subject to change</li>
          </ul>
        </>
      );
    }
  };

  const _joinWebinar = (joinURL) => {
    window.open(joinURL, "_blank").focus();
  };

  let isRegistered = false;

  // Check if already registered
  event.slots.forEach((slot) => {
    if (slot.alreadyRegistered) {
      isRegistered = true;
    }
  });
  console.log("event :>> ", event);
  // const speakers = JSON.parse(JSON.parse(JSON.stringify(event.speakers)));
  const eventLogo = JSON.parse(
    JSON.parse(JSON.stringify(event.description))
  ).eventLogoLink;

  const joinURL = _getJoinUrl(event);
  const certLink = _getCertLink(event);

  const isSouthCarolina = event.focusId === "tt41d8cd98f00b204e9800998ecf842ww";
  const speakersArray = Object.values(event?.formattedSpeakers);

  return (
    <>
      <div className="eventCardOuterWrapper">
        <div className="dateHighlighter">
          <i className="mdi mdi-calendar"></i>
          <p>
            {event.startDate === event.endDate ? (
              <>{moment(event.startDate, "DD/MM/YYYY").format("DD MMM")}</>
            ) : (
              <>
                {moment(event.startDate, "DD/MM/YYYY").format("DD MMM")} -{" "}
                {moment(event.endDate, "DD/MM/YYYY").format("DD MMM")}
              </>
            )}
            {"  "}
            {moment(event.startDate, "DD/MM/YYYY").format("YYYY ")}
          </p>
        </div>
        <div className="contentWrapper">
          <div className="upperPart">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 grid-margin grid-margin-md-0 stretch-card">
                  <div className="eventLogoWrapper">
                    <img src={eventLogo} alt="" />
                  </div>
                  {/* <h6>{event.focus}</h6> */}
                  {onlyVideos ? <></> : <>{_renderEventDetails()}</>}
                </div>
                <div className="col-lg-6">
                  {onlyVideos ? (
                    <></>
                  ) : (
                    <>
                      <div className="registerActions">
                        <div className="selectorLabel">
                          {isRegistered ? (
                            "Registered"
                          ) : (
                            <>
                              {event.isEnabled ? (
                                <>
                                  Select a slot <br /> to register
                                </>
                              ) : (
                                "Registration Closed"
                              )}
                            </>
                          )}
                        </div>
                        <div
                          className="eventTimeSlots"
                          style={
                            event?.isEnabled
                              ? {}
                              : {
                                  opacity: 0.3,
                                  pointer: "not-allowed",
                                }
                          }
                          data-for="registerArea"
                          data-tip={
                            event?.isEnabled ? "" : "Registration closed"
                          }
                        >
                          {event.slots.map((slot) => (
                            <>
                              {(slot.alreadyRegistered && isRegistered) ||
                              !isRegistered ? (
                                <div
                                  className={
                                    slot.alreadyRegistered
                                      ? "timeSlot registered"
                                      : isRegistered || !event.isEnabled
                                      ? "disabled timeSlot"
                                      : "timeSlot"
                                  }
                                  key={slot.slotId}
                                  onClick={(e) => {
                                    if (!isRegistered && event.isEnabled) {
                                      _showRegisterConfirmationPrompt(slot);
                                    }
                                  }}
                                >
                                  {slot.alreadyRegistered ? (
                                    <>
                                      <i class="mdi mdi-check"></i>
                                      &nbsp;{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {convert24To12(slot.startTime)} -{" "}
                                  {convert24To12(slot.endTime)} Eastern
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </div>
                        <ReactTooltip
                          place="right"
                          effect="solid"
                          id="registerArea"
                          offset="{'top': 0, 'right': 0}"
                        />
                        <FeaturedSponsorViewer event={event} />
                      </div>
                      {isRegistered && showRegisteredActions ? (
                        <div className="registeredActions">
                          {event.isEnabled ? (
                            <button
                              className="cancelBtn"
                              onClick={_showRegisterCancellationPrompt}
                            >
                              <i className="fas fa-user-times"></i> &nbsp;
                              Cancel Registration
                            </button>
                          ) : (
                            <></>
                          )}
                          {joinURL?.length ? (
                            <button
                              className="goto_webinar"
                              data-tip="Click to join"
                              data-for="btn"
                              onClick={(e) => _joinWebinar(joinURL)}
                            >
                              <i className="fas fa-eye"></i> &nbsp; Goto Webinar
                            </button>
                          ) : (
                            <button
                              data-tip="Will be available soon"
                              data-for="btn"
                            >
                              <i className="fas fa-eye"></i> &nbsp; Event Login
                              Information
                            </button>
                          )}
                          {certLink ? (
                            <>
                              <button
                                data-tip="Click to download"
                                data-for="btn"
                                onClick={_downloadCert}
                                className="downloadBtn"
                              >
                                <i className="fas fa-download"></i> &nbsp;
                                Download Certificate
                              </button>
                              <ReactTooltip
                                place="right"
                                effect="solid"
                                id="btn"
                                offset="{'top': 0, 'right': 0}"
                              />
                            </>
                          ) : (
                            <>
                              <button
                                data-tip="Will be available soon"
                                data-for="btn"
                              >
                                <i className="fas fa-download"></i> &nbsp;
                                Download Certificate
                              </button>
                              <ReactTooltip
                                place="right"
                                effect="solid"
                                id="btn"
                                offset="{'top': 0, 'right': 0}"
                              />
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
              {onlyVideos ? (
                <></>
              ) : (
                <>
                  <EventSponsorsViewer event={event} />

                  {event?.focusId === "tt41d8cd98f00b204e9800998ecf842ww" ? (
                    <>
                      <div className="row caDetails">
                        Midlands Technical College 1260 Lexington Drive, West
                        Columbia, SC 29170
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="accordion" id="speakerAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSpeakers">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseSpeakers-${event.eventId}`}
                          aria-expanded="true"
                          aria-controls={`collapseSpeakers-${event.eventId}`}
                        >
                          Click here to view Speakers
                        </button>
                      </h2>
                      <div
                        id={`collapseSpeakers-${event.eventId}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSpeakers"
                        data-bs-parent="#speakerAccordion"
                      >
                        <div className="accordion-body">
                          <div className="row justify-content-center">
                            <div className="col-lg-12">
                              <>
                                {speakersArray?.length ? (
                                  <div className="messageBox">
                                    Click on the speakers' logo to view details
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            </div>
                            {speakersArray?.map((speakers, dayIndex) => (
                              <div
                                className="col-lg-6 speakersWrapper"
                                key={dayIndex}
                              >
                                <div className="dayLabel">
                                  Day {dayIndex + 1}
                                </div>
                                {speakers?.map((speaker) => (
                                  <div
                                    className="speakerLogos"
                                    key={speaker.id}
                                  >
                                    <div className="imageWrapper">
                                      <img
                                        src={speaker.logo}
                                        alt=""
                                        data-tip="Click to view details"
                                        data-for={
                                          isSouthCarolina ? "one" : "test"
                                        }
                                        className={
                                          isSouthCarolina
                                            ? "speakerLogo caLogo"
                                            : "speakerLogo"
                                        }
                                        onClick={(e) =>
                                          isSouthCarolina
                                            ? () => {}
                                            : _showSpeakerDetails(speaker)
                                        }
                                      />
                                      {isSouthCarolina ? (
                                        <></>
                                      ) : (
                                        <>
                                          <ReactTooltip
                                            place="right"
                                            id="test"
                                            effect="solid"
                                            offset="{'top': 0, 'right': 20}'"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {event?.focusId === "tt41d8cd98f00b204e9800998ecf842ww" ? (
                <></>
              ) : (
                <>
                  {speakersArray?.length ? (
                    <>
                      {onlyVideos ? (
                        <>
                          <SpeakersVideoGrid allSpeakers={speakersArray} />
                        </>
                      ) : (
                        <>
                          {/* <div className="messageBox">
                            Click on the speakers' logo to view details
                          </div> */}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventViewer);
